.app {
  display: flex;
}

.sidebar {
  width: 150px;
  display: flex;
  flex-direction: column;
  background-color: #f0f0f0;
}

.sidebar .label-toggle {
  margin-top: 10px;
  padding: 10px;
}

.sidebar .label-toggle label {
  font-size: 12px;
}

.tile-button {
  padding: 10px;
  margin: 5px;
  cursor: pointer;
}

.grid {
  display: grid;
  grid-template-columns: repeat(20, 128px);
  grid-template-rows: repeat(20, 128px);
}

.tile {
  width: 128px;
  height: 128px;
  /* border: 1px solid #ccc; */
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tile:hover {
  filter: contrast(0.5);
}

.grass {
  background-color: green;
  background-image: url("../public/images/grass.png");
  background-size: cover;
}

.top-left-water {
  background-color: green;
  background-image: url("../public/images/top-left-water.png");
  background-size: cover;
}

.top-middle-water {
  background-color: green;
  background-image: url("../public/images/top-middle-water.png");
  background-size: cover;
}

.top-right-water {
  background-color: green;
  background-image: url("../public/images/top-right-water.png");
  background-size: cover;
}

.middle-left-water {
  background-color: green;
  background-image: url("../public/images/middle-left-water.png");
  background-size: cover;
}

.middle-right-water {
  background-color: green;
  background-image: url("../public/images/middle-right-water.png");
  background-size: cover;
}

.bottom-left-water {
  background-color: green;
  background-image: url("../public/images/bottom-left-water.png");
  background-size: cover;
}

.bottom-middle-water {
  background-color: green;
  background-image: url("../public/images/bottom-middle-water.png");
  background-size: cover;
}

.bottom-right-water {
  background-color: green;
  background-image: url("../public/images/bottom-right-water.png");
  background-size: cover;
}

.water {
  background-color: #00bfff;
}

.mountain {
  background-color: gray;
}

.empty {
  background-color: white;
}
